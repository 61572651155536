.mainStructure {

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
  }

  &__container {
    flex: 1;
    width: 100%;
    z-index: 0;
  }
}

