.relative {
  position: relative;
}

.wAuto {
  width: auto !important;
}

.wFull {
  width: 100% !important;
}

.w100 {
  width: 100% !important;
}

.w75 {
  width: 75% !important;
}

.w50 {
  width: 50% !important;
}

.w25 {
  width: 25% !important;
}

.hAuto {
  height: auto !important;
}

.hFull {
  height: 100% !important;
}

.borderRadiusMd {
  border-radius: var(--brand-border-radius-md);
}

.border {
  border-width: var(--brand-border-width-brand);
  border-color: var(--theme-color-border-theme);
}

.blogPreviewSmall {
  max-width: 368px
}

.colorOverlay {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.colorOverlayPrimary {
  background-color: var(--component-surfaces-overlay-container-color-background-scrimmed-primary);
}

.colorOverlayDefault {
  background-color: var(--component-surfaces-overlay-container-color-background-scrimmed-default);
}

.videoWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: none;
  min-height: 100%;
  z-index: 0;
}

.contentLayer {
  position: relative;
}
