@import '../../../scss/mixins';

.productCard {

  &__container {
    width: 100%;
    max-width: 272px;
  }

  &__placeholder {
    position: relative;
    pointer-events: none;
  }

  @include errorState()
}
