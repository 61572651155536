.EmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--theme-spacing-2xl);

  &__Icon {
    position: relative;
    height: calc(var(--theme-spacing-3xl) * 1.95);
    width: calc(var(--theme-spacing-3xl) * 1.87);

    &Container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(var(--theme-spacing-4xl) * 2);
      height: calc(var(--theme-spacing-4xl) * 2);
      box-sizing: border-box;
      border-radius: 50%;
      background-color: var(--theme-color-text-on-primary-strong);
    }

    &Question {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--theme-spacing-3xl);
      height: var(--theme-spacing-3xl);
      border: 2px solid var(--theme-color-text-on-primary-strong);
      border-radius: 50%;
      background-color: var(--theme-color-background-primary);
      color: white;
    }

    &Free {
      position: absolute;
      left: 0;
      bottom: 0;
      color: var(--theme-color-background-secondary);

      i {
        font-size: 40px;
        line-height: 40px;
      }
    }
  }
}
