@import '../../../../scss/mixins';

.list {
  display: grid;
  gap: var(--theme-spacing-7xl);
  grid-template-columns: repeat(6, 1fr);

  @include isTablet() {
    grid-template-columns: repeat(3, 1fr);
  }

  @include isMobile() {
    grid-template-columns: repeat(2, 1fr);
  }
}

.listPreview {
  gap: var(--theme-spacing-xl);
}

.w100 {
  width: 100%;
}