.detailHeader {
  width: 100%;
}

.paragraph {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.maxLines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
