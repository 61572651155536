@mixin isDesktop() {
  @media (min-width: 906px){
    @content;
  }
}

@mixin isTablet() {
  @media (min-width: 600px) and (max-width: 905px){
    @content;
  }
}

@mixin isMobile() {
  @media (max-width: 600px){
    @content;
  }
}

@mixin errorState() {
  &__error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, transparent 35%, color-mix(in srgb, var(--theme-color-text-system-danger) 20%, transparent) 50%, transparent 65%);
    background-size: 300% 100%;
    background-position-x: 200%;
    animation: shimmer 5s infinite linear;
    outline: 3px solid var(--theme-color-text-system-danger);
    outline-offset: 2px;

    @keyframes shimmer {
      to {
        background-position-x: -100%
      }
    }
  }
}
