.table {
  &Border {
    *:last-child >  tr:last-of-type {
      border-bottom: none
    }
  }

  &Hover {
    td {
      transition: all 0.15s cubic-bezier(.4,0,.2,1);
    }

    tr:hover > td {
      background-color: var(--component-data-display-table-cell-color-background-regular-hover) !important;
    }
  }

  &Rounded {
    border-radius: calc(var(--component-data-display-table-border-radius) - 1px);

    *:first-child > tr:first-of-type {
      border-top-left-radius: calc(var(--component-data-display-table-border-radius) - 1px);
      border-top-right-radius: calc(var(--component-data-display-table-border-radius) - 1px);

      th:first-of-type, td:first-of-type {
        border-top-left-radius: calc(var(--component-data-display-table-border-radius) - 1px);
      }

      th:last-of-type, td:last-of-type {
        border-top-right-radius: calc(var(--component-data-display-table-border-radius) - 1px);
      }
    }

    *:last-child > tr:last-of-type {
      border-bottom-left-radius: calc(var(--component-data-display-table-border-radius) - 1px);
      border-bottom-right-radius: calc(var(--component-data-display-table-border-radius) - 1px);

      th:first-of-type, td:first-of-type {
        border-bottom-left-radius: calc(var(--component-data-display-table-border-radius) - 1px);
      }

      th:last-of-type, td:last-of-type {
        border-bottom-right-radius: calc(var(--component-data-display-table-border-radius) - 1px);
      }
    }
  }

  &RowDividers {
    tr {
      border-bottom: var(--component-data-display-table-border-width) solid var(--component-data-display-table-color-border);
    }
  }

  &StrokedHorizontal tbody {
    tr:nth-child(odd) {
      background: var(--component-data-display-table-cell-color-background-striped-default);

      &:Hover {
        background: var(--component-data-display-table-cell-color-background-striped-hover);
      }
    }
  }
}
