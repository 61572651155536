.autoSuggest {

  &__mobileContainer {
    position: fixed;
    inset: 0;
    background: var(--theme-color-surface-1);
    z-index: -10;
    opacity: 0;
    overflow: auto;

    &__expanded {
      z-index: 80;
      opacity: 1;
    }
  }
}
